<template>
  <main 
    :class="classes"
    :style="style">
    <div 
      class="layout-claim__imgbanner" 
      v-if="banner">
      <img
        id="claim-banner"
        :alt="name"
        class="layout-claim__img"
        :src="banner"
      />
    </div>

    <!-- Loading -->
    <div 
      class="layout-claim__loader" 
      v-if="loading">
      <ui-loader />
    </div>

    <div 
      class="layout-claim__container" 
      v-if="!loading">
      <!-- Form -->
      <div 
        class="layout-claim__forms" 
        v-if="!hasError">
        <email-view />
      </div>

      <ui-nft-intro 
        :error="claimError"
        :has-error="hasError"
        :vouchers="vouchers"
      />
    </div>
  </main>
</template>

<script>
import MixinClaimError from '@/modules/claim/helpers/errors.js'
import MixinRouteLeave from '@/modules/claim/helpers/router-leave.js'
import MixinSelfClaim from '@/modules/claim/helpers/self-claim.js'

import EmailView from '@/modules/claim/views/nfts/self/email.vue'

import UiNftIntro from '@/modules/claim/components/ui/nft-intro.vue'

export default {
  name: 'ClaimNft',

  components: {
    EmailView,
    UiNftIntro,
  },

  inject: [
    '$nft',
    '$user',
    '$wallet'
  ],
  
  mixins: [ 
    MixinClaimError,
    MixinRouteLeave,
    MixinSelfClaim,
  ],

  data() {
    return {
      loading: false,
      bannerHeight: 0,
      vouchers: [],
    }
  },

  computed: {
    banner() {
      let ret = null

      let banners = this.$basil.get(this.nft, 'embed.banners',[])
      if(banners && banners.length > 0) {
        ret = banners[0]
      }

      return ret
    },

    classes() {
      return {
        'container': true,
        'layout-claim__body': true,
        '-is-loading': this.loading
      }
    },  

    name() {
      let ret = this.$basil.get(this.nft, 'name', null)

      if(!ret) {
        ret = this.$basil.get(this.nft, 'contract.name', null)
      }
      
      return ret
    },

    nft() {
      return this.$nft.nft || null
    },

    style() {
      return `--banner-height: ${this.bannerHeight}px;`
    },

    transfer() {
      return this.$nft.transfer || null
    },

    user() {
      return this.$user.user || null
    }
  },

  methods: {
    resize() {
      let el = document.getElementById('claim-banner')
      if(el && this.banner != null) {
        this.bannerHeight = el.getBoundingClientRect().height

        if(this.bannerHeight < 400) {
          this.bannerHeight = 2 * this.bannerHeight
        }

        if(this.bannerHeight == 0 && this.banner != null) {
          setTimeout(() => this.resize(), 100)
        }
      }
    },

    async reset() {
      try {
        this.loading = true
        await this.view({ id: this.$route.params.id })

        // try {
        //   this.vouchers = await this.$wallet.getVouchersForOneNft({ 
        //     address: this.$basil.get(this.transfer, 'tokenId'), 
        //     serial: this.$basil.get(this.transfer, 'serial')
        //   })
        // } catch(e) {} 

        if(this.$basil.get(this.nft, 'claimable', false) === false) {
          this.hasError = true

          this.claimError = {
            status: 400,
            title: this.$t('user-portal.nft_self_claim_not_claimble'),
            description: this.$t('user-portal.nft_self_claim_not_claimble_description'),
          }
        }
      } catch(e) {
        this.handleClaimErrors(e)
      } finally {
        this.loading = false
        setTimeout(() => this.resize(), 100) 
      }
    }
  },

  mounted() {
    this.reset()
    window.addEventListener('resize', this.resize)
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.resize)
  }
}
</script>